import loader from './css/loader.css';
import { get_selected_db_id, is_genome_selected } from './common/hash';

loader.use();
import { show_loader } from './common/ui';

require('./common/global_functions');
require('./common/els_log');
require('./common/text_decoder');
require('./common/global_variables');
require('./common/alpine');
require('./glaze/search_manufacturing');

globalThis.active_document_tab = get_parameter('tab') || 'main';

const login_template = require('./view/login_form.handlebars');
const login_html = login_template({});
const content_template = require('./view/content.handlebars');
const content_html = content_template({});
const navbar_template = require('./view/navbar/navbar.handlebars');
const navbar_html = navbar_template({ is_db_genome: is_genome_selected() });
const user_template = require('./view/content_user.handlebars');
const user_html = user_template({});
const usercompany_template = require('./view/content_usercompany.handlebars');
const usercompany_html = usercompany_template({});

const app_mount_point = document.getElementById('app-mount-point');

app_mount_point.insertAdjacentHTML('beforeend', navbar_html);
app_mount_point.insertAdjacentHTML('beforeend', login_html);
app_mount_point.insertAdjacentHTML('beforeend', content_html);
app_mount_point.insertAdjacentHTML('beforeend', user_html);
app_mount_point.insertAdjacentHTML('beforeend', usercompany_html);

import { DateTime } from 'luxon';
globalThis.DateTime = DateTime;

show_loader();

import { rpc } from './common/crystal_api/rpc';
import { ceramics_genome_page_loaded } from './common/login';
import './common/router';
import './glaze/search_table';
import './ligo/search_report_table.js';
import './glaze/compare';
import './glaze/formulation';
import { create_new_user } from './common/user';
import { create_navbar_elements } from './common/navbar_menus';
import index from './common/css/tailwind_imports.css';
import font_awesome from '@fortawesome/fontawesome-free/css/all.css';
import './glaze/options/option_attachments';
import './glaze/options/option_boolean';
import './glaze/options/option_chooser';
import './glaze/options/option_multichooser_classes';
import './glaze/options/option_gps';
import './glaze/options/option_log';
import './glaze/options/option_meta';
import './glaze/options/option_numbers';
import './glaze/options/option_ranges';
import './glaze/options/option_role';
import './glaze/options/option_url';
import './glaze/options/option_table';
import './glaze/options/option_datetime';
import './glaze/options/option_textarea';
import './glaze/options/option_string';
import './glaze/options/option_section';
import './glaze/options/option_captcha';
import './glaze/material_semi';
import { highlight_clicks } from './common/mouse';
require('./ligo/samples_shapes.js');
import './common/svg_paths';
import './common/units';

font_awesome.use();

require('./glaze/model.js');

globalThis.XLSX = require('xlsx-js-style');

index.use();
create_navbar_elements();

import('./common/media/agent.svg').then((svg) => (document.getElementById('user-menu-button').src = svg.default));

function register_new_user() {
  rpc.user_new().then((response) => create_new_user(response));
}

function sync_advanced_search_box_with_url() {
  ['advanced-search-data'].forEach((id) => {
    const element = document.getElementById(id);
    if (element && element._x_dataStack) element._x_dataStack[0].advancedSearch = !!get_parameter('search');
  });
}

function show_home(e) {
  globalThis.search_genome_object = undefined;
  globalThis.glaze_search_table = undefined;
  globalThis.report_search_doc = undefined;
  remove_element('sign-in_div');
  document.getElementById('search_in_home')._x_model.set('');
  sync_advanced_search_box_with_url();
  if (e.ctrlKey) {
    window.open(get_selected_db_id());
  } else {
    globalThis.location.href = get_selected_db_id();
    dispatch_event('show-search-results');
  }
}

ceramics_genome_page_loaded();

if (globalThis.pyodide_enabled) {
  import('./common/pyodide').then((pyodide) => pyodide.default.init());
} else {
  document.querySelector('#loader').classList.add('unload');
  document.body.style.overflowY = 'auto';
  document.body.style.overflowX = 'hidden';
}

globalThis.register_new_user = register_new_user;
globalThis.show_home = show_home;

get_cookie('should_highlight_clicks') && highlight_clicks();
