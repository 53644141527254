var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a\n  x-data='{ ref: \"javascript:void(0);\" }'\n  x-text='_(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"button_text") || (depth0 != null ? lookupProperty(depth0,"button_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"button_text","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":28}}}) : helper)))
    + "\")'\n  id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"doc_type") || (depth0 != null ? lookupProperty(depth0,"doc_type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"doc_type","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":18}}}) : helper)))
    + "'\n  :href='new_document_url(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"doc_type") || (depth0 != null ? lookupProperty(depth0,"doc_type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"doc_type","hash":{},"data":data,"loc":{"start":{"line":5,"column":27},"end":{"line":5,"column":39}}}) : helper)))
    + "\")'\n  role='menuitem'\n  tabindex='-1'\n  class='relative cursor-pointer list-none inline text-gray-300 hover:!text-white whitespace-nowrap leading-10 px-4'\n></a>";
},"useData":true});