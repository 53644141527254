var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class='pl-4 divide-x divide-gray-300'>\n"
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Formulation","button_text":"Formulation"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"Compare","button_text":"Document comparison"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"raw","button_text":"Raw material"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"semi","button_text":"Semi finished"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"product","button_text":"Product"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"oxide","button_text":"Oxide"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"compound","button_text":"Compound"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./navbar_anchors.handlebars"),depth0,{"name":"navbar_anchors","hash":{"doc_type":"mix","button_text":"Mix"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});